
import { request } from '@/services';
import { AxiosRequestConfig } from 'axios';
import { AnyObject } from 'element-plus/lib/el-table/src/table.type';
import { Request } from '@/services/requests/request';
interface tableList {
  goodNum: number
  id: number
  relDate: string
  relFrom: string
  relNum: number
  relPv: number
  relStata: boolean
  relText: string
  relUser: string
}
export interface resultInterface<T> {
  ret?: number;
  msg?: string;
  data: T | AnyObject
}
export interface replyInterface {
  pageInfo: number
  commentVerifyData?: Array<tableList>
}


interface reqFieldInfo {
  commentId: number;
  manageType: number;
  reason?: string;
}

// 获取帖子结构预览Api
export function getReplyTemplate(req: AnyObject):
Promise<Request<AxiosRequestConfig, resultInterface<replyInterface>>> {
  return request<AxiosRequestConfig, resultInterface<replyInterface>>({
    url: '/api/get_relpy_table_list',
    method: 'post',
    data: req,
  });
}

// 回复审核操作Api
export function reviewReplyApi(req: reqFieldInfo):
Promise<Request<AxiosRequestConfig, resultInterface<replyInterface>>> {
  return request<AxiosRequestConfig, resultInterface<replyInterface>>({
    url: '/api/manage/comment/manage',
    // url: '/api/get_relpy_table_list',
    method: 'post',
    data: req,
  });
}
// 回复审核表格数据Api
export function reviewReplyTableApi(req: AnyObject):
Promise<Request<AxiosRequestConfig, resultInterface<replyInterface>>> {
  return request<AxiosRequestConfig, resultInterface<replyInterface>>({
    url: '/api/manage/verify/get_comment_verify_list',
    // url: '/api/get_relpyReview_table_list',
    method: 'post',
    data: req,
  });
}
