
import { defineComponent, reactive, onMounted, provide, computed } from 'vue';
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus';
import { reviewReplyTableApi, reviewReplyApi } from '../../reactivity/getReviewReplay';
import { searchInfoType } from '../../../types/reply';
import SenderTemplate from '../common/SenderTemplate.vue';
import ReplySearch from '../common/ReplySearch.vue';
import IframeContainer from '../common/IframTem.vue';
import { approvalState } from '../../utils/index';
import { replyMessage } from '../../utils/numberFromat';
import { getTimeToUnix,  relDateFormat, clearHtml } from '@/utils/getTimeToUnix';

interface rowInfoType {
  commentId: number,
  commentContent: string,
  postContent: string,
  createNickName: string,
  icon: string,
  createTimestamp: number,
  replyNum: number,
  likeNum: number,
  verifyStatus: number,
  createWx: number,
  createQQ: number,
  uin: string,
  loginType: number,
  userId: number,
}

export default defineComponent({
  components: {
    ReplySearch,
    SenderTemplate,
    IframeContainer,
  },
  setup() {
    const store = useStore();
    const data = reactive({ // 页面数据
      reaplyTempDailogVisible: false, // 帖子解构预览弹窗
      senderInfoDailogVisible: false, // 发表者信息弹窗
      commentId: 0,
      needOpenId: 0,
      useType: 1,
      verifyStatus: 3,
      tableLoading: false,
      verifyStateOptions: [
        { label: '全部', value: 0 },
        // { label: '机审中', value: 1 },
        { label: '机审未通过', value: 2 },
        { label: '未审核', value: 3 },
        { label: '审核通过', value: 4 },
        { label: '审核未通过', value: 5 },
        { label: '已删除', value: 6 },
      ],
      iframeUrl: '',
      commentList: [],
      searchInfo: {},
      senderInfo: {
        createIcon: '', // 发表者头像
        createNickName: '', // 发表者昵称
        createWx: '', // 发表者微信
        createQQ: '', // 发表者QQ
      },
      pageInfo: {
        total: 0,
        pageNum: 1,
        pageSize: 5,
      },
      tableHeight: 300,
      showAll: true, // 是否展开或者折叠
      userInfo: computed(() => store.getters.userInfo),
    });
    provide('selectOptions', data.verifyStateOptions);
    provide('useType', data.useType);
    provide('verifyStatus', data.verifyStatus);
    provide('senderInfoDailogVisible', data);
    provide('needIframeData', data);
    const addressColor = (state: number, roleId: number, buttonColor: string) => {
      // 操作删除时候禁用
      const obj = {
        style: `color: ${buttonColor};font-size: 14px`,
        disable: true,
      };
      if (roleId === 1 || roleId === 3) {
        switch (true) {
          case state !== 3:
            obj.style = 'color: #C0C4CC;font-size: 14px';
            obj.disable = true;
            return obj;
          default:
            obj.style = `color: ${buttonColor};font-size: 14px`;
            obj.disable = false;
            return obj;
        }
      }
      return obj;
    };
    const showUseInfo = (rowInfo: rowInfoType) => {
      data.senderInfo.createIcon = rowInfo.icon;
      data.senderInfo.createNickName = rowInfo.createNickName;
      data.senderInfo.createWx = rowInfo.loginType === 2 ? rowInfo.userId.toString() : '/';
      data.senderInfo.createQQ = rowInfo.loginType === 1 ? rowInfo.userId.toString() : '/';
      data.senderInfoDailogVisible = true;
    };
    const openReaplyTempDailog = (id: number) => { // 预览H5帖子弹窗
      data.needOpenId = id;
      data.reaplyTempDailogVisible = true;
    };
    const reviewReplayBtn = (id: number, state: number) => { // 审核操作  通过/不通过
      data.commentId = id;
      const reqdata = {
        commentId: data.commentId,
        manageType: state,
        reason: '',
      };
      reviewReplyApi(reqdata)
        .then((res) => {
          if (res.data?.ret === 0) {
            ElMessage.success({
              message: '审核成功',
              type: 'success',
            });
            handleSearch();
          } else {
            ElMessage.error({
              message: '审核失败',
              type: 'error',
            });
          }
        });
    };
    const pageNumChange = (num: number) => { // 改变页数
      data.pageInfo.pageNum = num;
      handleSearch();
    };
    const getTableData = (req: searchInfoType) => { // 初始化获取表格数据
      data.tableLoading = true;
      reviewReplyTableApi(req).then((res) => {
        try {
          if (res.data?.ret === 0) {
            const { commentVerifyData, pageInfo } = res.data?.data;
            data.commentList = commentVerifyData || [];
            data.pageInfo = {
              ...pageInfo,
            };
          }
        } catch (error) {
          console.error(error);
        }
      })
        .finally(() => {
          data.tableLoading = false;
        });
    };
    const pageSizeChange = (num: number) => { // 改变每页显示条数
      data.pageInfo.pageSize = num;
      data.pageInfo.pageNum = 1;
      handleSearch();
    };
    window.onresize = () => (() => { // 监听窗口高度,进行table高度控制
      changeTableMaxHeight();
    })();
    const changeTableMaxHeight = () => { // 改变表格最高高度
      data.tableHeight = document.body.clientHeight - 56 - (data.showAll ? 245 : 112) - 24 - 100;
    };
    changeTableMaxHeight(); // 初始化显示表格高度
    onMounted(() => { // 生命钩子
      handleSearch(); // 初始化获取表格数据
    });
    const getSearchInfo = (obj: searchInfoType) => { // 传到子组件让子组件调用搜索功能
      data.searchInfo = obj;
      data.pageInfo.pageNum = 1;
      handleSearch();
    };
    const changeSerchStype = (isAll: boolean) => { // 收起/展开按钮,并且重置table高度
      data.showAll = isAll;
      changeTableMaxHeight();
    };
    const handleSearch = () => { // 搜索按钮FN
      const searchInfo: searchInfoType = { // 搜索数据
        verifyStatus: 3,
        ...data.searchInfo,
        pageNum: data.pageInfo.pageNum,
        pageSize: data.pageInfo.pageSize,
      };
      Object.keys(searchInfo).forEach((k: string) => { // 处理搜索入参
        if (!searchInfo[k] && k !== 'verifyStatus') {
          delete searchInfo[k];
        } else {
          if (k !== 'createWx'
            && k !==  'createQQ'
            && k !==  'createNickName'
            && k !==  'communityName'
            && k !== 'sendDate') {
            searchInfo[k] = Number(searchInfo[k]);
          }
        }
        if (k === 'sendDate' && searchInfo.sendDate) {
          if (searchInfo.sendDate.length > 0) {
            Object.assign(searchInfo, {
              createBeginTimestamp: getTimeToUnix(searchInfo.sendDate[0].toString()),
              createEndTimestamp: getTimeToUnix(searchInfo.sendDate[1].toString()),
            });
          }
          delete searchInfo[k];
        }
      });
      getTableData(searchInfo);
    };
    return {
      handleSearch, // 检索按钮FN
      changeSerchStype, // 收起展开FN
      data, // 页面总数据
      pageNumChange, // 分页改变当前页
      pageSizeChange, // 每页显示条数
      reviewReplayBtn, // table删除按钮FN
      openReaplyTempDailog, // 打开回复内容布局弹窗
      approvalState,
      relDateFormat, // 发表时间格式化
      showUseInfo,
      getSearchInfo,
      clearHtml,
      addressColor,
      replyMessage,
    };
  },
});
